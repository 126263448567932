import styled, { css } from 'styled-components';
import theme, { media } from '../theme';

const base = css`
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  font-family: ${(props) => (props.serif ? theme.fonts.serif : theme.fonts.sans)};
  line-height: 1.5;
  text-align: ${(props) => (props.align ? props.align : 'initial')};
`;

const H1 = styled.h1`
  ${base};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[4]};
  letter-spacing: 0.13em;

  ${media[1]`
    font-size: ${theme.fontSizes[5]};
  `};
  ${media[2]`
    font-size: ${theme.fontSizes[6]};
  `};
`;

const H2 = styled.h1`
  ${base};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[3]};

  ${media[1]`
    font-size: ${theme.fontSizes[4]};
  `};
  ${media[2]`
    font-size: ${theme.fontSizes[5]};
  `};
`;

const H3 = styled.h3`
  ${base};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[2]};

  ${media[1]`
    font-size: ${theme.fontSizes[3]};
  `};
  ${media[2]`
    font-size: ${theme.fontSizes[4]};
  `};
`;

const H4 = styled.h4`
  ${base};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[1]};
  letter-spacing: 0.06em;

  ${media[1]`
    font-size: ${theme.fontSizes[2]};
  `};
  ${media[2]`
    font-size: ${theme.fontSizes[3]};
  `};
`;

const H5 = styled.h5`
  ${base};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes[4]};
  letter-spacing: 0.06em;
  margin: 0;

  ${media[0]`
    font-size: ${theme.fontSizes[5]};
  `};
  ${media[1]`
    font-size: ${theme.fontSizes[2]};
  `};
  ${media[2]`
    font-size: ${theme.fontSizes[2]};
  `};
`;

const P = styled.p`
  ${base};
  font-size: ${theme.fontSizes[1]};

  ${media[2]`
    font-size: ${theme.fontSizes[2]};
  `};
`;

const Label = styled.span`
  ${base};
  font-size: calc(${theme.fontSizes[0]} + 0.3vw);
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  Label,
};
