import React from 'react';
import Helmet from 'react-helmet';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import 'normalize.css';

import Nav from './navbar';
import Footer from './footer';
import theme from '../theme';
import LayoutWrapper from './layout-wrapper';
import { LanguageContext } from './language-context-provider';

const GlobalStyle = createGlobalStyle`
  html{
    font-size: ${theme.rootFontSize};
    user-select: none;
  }
  body {
    background: ${theme.colors.white};
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
  }
  *, *:before, *:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
          query IndexLayoutQuery {
            settings: allMarkdownRemark(
              filter: { frontmatter: { templateKey: { eq: "settings" } } }
            ) {
              edges {
                node {
                  frontmatter {
                    address
                  }
                }
              }
            }

            sections: allMarkdownRemark(
              filter: {
                frontmatter: { templateKey: { eq: "section" }, inFooter: { eq: true } }
              }
            ) {
              edges {
                node {
                  frontmatter {
                    title {
                      en
                      it
                    }
                    pageSlug
                    footerPosition
                    section
                  }
                }
              }
            }

            contentPages: allMarkdownRemark(
              filter: { frontmatter: { templateKey: { eq: "content-page" } } }
            ) {
              edges {
                node {
                  frontmatter {
                    section
                    pageSlug
                    title {
                      en
                      it
                    }
                  }
                }
              }
            }
          }
        `}
    render={(data) => (
      <>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <LayoutWrapper>
            <Helmet title="Contec">
              <link
                href="https://fonts.googleapis.com/css?family=Barlow:500|Cabin|Fira+Sans|Hind+Guntur|Karla|Krub:500|Molengo|Mukta|Noto+Serif+JP|PT+Sans|Tauri"
                rel="stylesheet"
              />
              <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
            </Helmet>
            <Nav notIndex={typeof window !== 'undefined' && window.location.pathname.length > 1} />
            {children}
            <LanguageContext.Consumer>
              {({ language }) => (
                <Footer
                  address={data.settings.edges[0].node.frontmatter.address}
                  sections={data.sections.edges}
                  pages={data.contentPages.edges}
                  lang={language}
                />
              )}
            </LanguageContext.Consumer>
          </LayoutWrapper>
        </ThemeProvider>
      </>
    )}
  />
);

export default Layout;
