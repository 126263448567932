/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown/with-html';
import Text from './text';
import Elements from './elements';
import theme, { media } from '../theme';

const StyledContainer = styled.div`
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
  ${(props) => (props.padding ? `padding: ${props.padding}` : null)};
  max-width: ${theme.maxWidths[0]};

  ${media[2]`
    max-width: ${theme.maxWidths[1]};
  `};
`;

const renderers = {
  paragraph: (props) => <Text.P {...props} />,
  link: (props) => <Elements.A {...props} />,
  list: (props) => (props.ordered ? <Elements.Ol {...props} /> : <Elements.Ul {...props} />),
  listItem: (props) => <Elements.Li {...props} />,
  heading: (props) => {
    switch (props.level) {
      case 1:
        return <Text.H1 {...props} />;
      case 2:
        return <Text.H2 {...props} />;
      case 3:
        return <Text.H3 {...props} />;
      case 4:
        return <Text.H4 {...props} />;
      case 5:
        return <Text.H5 {...props} />;
      default:
        return <Text.H5 {...props} />;
    }
  },
};

export default ({
  markdown, plainText, children, padding, margin,
}) => (
  <StyledContainer padding={padding} margin={margin}>
    {markdown && <ReactMarkdown escapeHtml={false} source={markdown} renderers={renderers} />}
    {children && <Text.P key="child-text">{children}</Text.P>}
    {!!plainText
      && plainText
        .split(/\n+/)
        .map((p) => <Text.P key={p.substr(0, 20)}>{p}</Text.P>)}
  </StyledContainer>
);
