import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Text from './text';
import theme, { media } from '../theme';

const Margin = styled.div`
  margin-bottom: ${theme.spacing[0]};
  text-align: center;

  ${media[1]`
    text-align: initial;
  `};
`;

const NavItem = styled(Text.P)`
  color: ${theme.colors.white};
  cursor: pointer;
`;
const NavLink = styled(Link)`
  display: inline;
  text-decoration: none;
`;

export default ({ pages, lang }) => (
  <>
    {pages.map(({ node: page }) => (
      <Margin>
        <NavLink key={page.frontmatter.title.en} to={page.frontmatter.pageSlug}>
          <NavItem as="span">{page.frontmatter.title[lang]}</NavItem>
        </NavLink>
      </Margin>
    ))}
  </>
);
