import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { LanguageContext } from './language-context-provider';
import Text from './text';

const Switch = styled.div`
  appearance: none;
  background: ${theme.colors.black};
  cursor: pointer;
  border: none;
  opacity: 0.4;
  padding: 0.25rem 0.5rem;
  transition: opacity 0.15s linear;

  &:hover {
    opacity: 1;
  }

  position: fixed;
  right: calc(1.5rem);

  > span {
    font-size: 0.8rem;
  }
`;

const Label = styled(Text.Label)`
  color: ${theme.colors.white};
  display: block;
  opacity: ${(props) => (props.current ? 1 : 0.3)};
  transition: opacity 0.1s;
  text-align: center;
`;

export default () => (
  <LanguageContext.Consumer>
    {({ toggleLanguage, language }) => (
      <Switch onClick={toggleLanguage} role="button">
        <Label current={language === 'en'}>EN</Label>
        <Label current={language === 'it'}>IT</Label>
      </Switch>
    )}
  </LanguageContext.Consumer>
);
