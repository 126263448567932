import styled, { css } from 'styled-components';
import theme from '../theme';

const base = css`
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  font-family: ${(props) => (props.serif ? theme.fonts.serif : theme.fonts.sans)};
  line-height: 1.5;
  text-align: ${(props) => (props.align ? props.align : 'initial')};
`;

const A = styled.a`
  ${base};
`;

const Ul = styled.ul``;

const Ol = styled.ol``;

const Li = styled.li`
  ${base};
`;

export default {
  A,
  Ul,
  Ol,
  Li,
};
