import React from 'react';
import styled from 'styled-components';
import theme, { media } from '../theme';
import Text from './text';
import ColumnSection from './column-section';

const Container = styled.nav`
  background-color: ${theme.colors.blue};
  padding: ${theme.spacing[2]} 0;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: ${theme.maxWidths[2]};
  flex-direction: column;

  ${media[1]`
    flex-direction: row;
`};
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
`;

export const FooterHeadline = styled(Text.H5)`
  color: ${theme.colors.white};
  margin: 1rem auto;
  position: relative;
  text-align: center;
  font-size: ${theme.fontSizes[2]} !important;
  width: 10rem;

  &:after {
    background-color: ${theme.colors.white};
    bottom: -0.5rem;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ${media[1]`
    margin: 1rem 0;
    width: initial;
    text-align: initial;
  `};
`;

const NavList = styled(Text.P)`
  color: ${theme.colors.white};
  font-size: 0.8rem !important;
  margin: 0.1rem 0;
  text-align: center;

  &:last-child {
    margin-bottom: 1rem;
  }

  ${media[1]`
    text-align: initial;
  `};
`;

const sortFooterColumns = (a, b) => {
  if (a.node.frontmatter.footerPosition < b.node.frontmatter.footerPosition) {
    return -1;
  }
  if (a.node.frontmatter.footerPosition > b.node.frontmatter.footerPosition) {
    return 1;
  }
  return 0;
};

const Footer = ({
  address, pages, lang, sections,
}) => (
  <Container>
    <InnerContainer>
      <FooterColumn>
        <FooterHeadline color="white">Contact</FooterHeadline>
        {address.split(/\n+/).map((p) => (
          <NavList key={p.substr(0, 4)}>{p}</NavList>
        ))}
      </FooterColumn>
      {sections && sections.sort(sortFooterColumns).map(({ node: section }) => (
        <FooterColumn>
          <FooterHeadline color="white">{section.frontmatter.title[lang]}</FooterHeadline>
          <ColumnSection
            key={section.frontmatter.pageSlug}
            pages={pages.filter(
              ({ node: page }) => page.frontmatter.section === section.frontmatter.pageSlug,
            )}
            lang={lang}
          />
        </FooterColumn>
      ))}
    </InnerContainer>
  </Container>
);

export default Footer;
