import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../theme';

const StyledLink = styled(Link)`
  left: 0;
  position: absolute;
  text-decoration: none;
`;

const BackButton = styled.div`
  appearance: none;
  border: none;
  color: ${theme.colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 2.3rem;
  justify-items: center;
  opacity: 0.4;
  transition: opacity 0.15s linear;
  width: 4.7rem;

  &:hover {
    opacity: 1;
  }


  > span {
    font-size: 0.8rem;
  }
`;

export default () => (
  <StyledLink to="/">
    <BackButton>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M503 229L274 20c-10-10-26-10-36 0L9 229c-8 7-11 19-7 29s13 17 24 17h37v209c0 9 7 15 15 15h126c8 0 15-6 15-15V357h74v127c0 9 7 15 15 15h126c8 0 15-6 15-15V275h37c11 0 20-7 24-17s1-22-7-29zM445 43H344l116 105V58c0-9-7-15-15-15z" /></svg>
    </BackButton>
  </StyledLink>
);
