import { css } from 'styled-components';

const scale = size => `calc(${size} + 0.1vw)`;

const theme = {
  colors: {
    white: '#F9F7F7',
    lightBlue: '#E9ECF2',
    blue: '#46668C',
    darkBlue: '#112D4E',
    black: '#3B3B3B',
  },
  fonts: {
    heading: '"Barlow", sans',
    sans: '"Cabin", sans',
    serif: '"Noto Serif", serif',
  },
  fontSizes: [
    '0.6rem',
    scale('0.9rem'),
    scale('1rem'),
    scale('1.2rem'),
    scale('1.4rem'),
    scale('1.8rem'),
    scale('2rem'),
  ],
  maxWidths: ['28rem', '38rem', '78rem', '105rem'],
  spacing: ['0.5rem', '0.75rem', '1rem', '2.5rem', '5rem'],
  rootFontSize: '15px',
  breakpoints: ['25rem', '35rem', '60rem'],
};

export const media = theme.breakpoints.map(bp => (...args) => css`
  @media (min-width: ${bp}) {
    ${css(...args)};
  }
`);

export const breakpoint = () => {
  for (let i = theme.breakpoints.length - 1; i >= 0; i--) {
    if (window.matchMedia(`(min-width:${theme.breakpoints[i]})`).matches) {
      return i;
    }
  }
  return -1;
};

export default theme;
