import React from 'react';
import styled from 'styled-components';
import LanguageToggle from './language-toggle';
import BackButton from './back-button';

const Navbar = styled.nav`
  position: absolute;
  width: 100%;
  z-index: 2;

  > ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 6rem;
    justify-content: center;
    list-style: none;
    margin: 0 auto;
    max-height: 25rem;
    padding: 0;
    position: relative;
    width: 100%;
  }
`;

const Nav = ({ notIndex }) => (
  <Navbar notIndex={notIndex}>
    <ul>
      <LanguageToggle />
      {notIndex && <BackButton />}
    </ul>
  </Navbar>
);

export default Nav;
